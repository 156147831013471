<template>
	<v-row>
		<v-col cols="12">
			<v-form ref="form" lazy-validation class="d-flex flex-column">
				<v-text-field
					v-model="data.name"
					class="border-all flex-grow-1 mr-5"
					placeholder="Название услуги"
					solo
					hide-details
					dense
					flat
					:rules="rules.required"
				></v-text-field>

				<div class="pricelist__inputs d-flex mt-3">
					<!-- Тип цены -->
					<v-select
						v-model="data.type"
						style="max-width: 250px;"
						class="border-all mr-4"
						placeholder="Выберите тип цены"
						flat
						dense
						solo
						hide-details
						:items="priceTypes"
						:rules="rules.required"
					/>
					<!-- Фиксированная цена -->
					<v-text-field
						v-if="data.type === 'fix_price'"
						style="max-width: 150px;"
						v-model="data.price"
						class="border-all"
						placeholder="Введите цену"
						type="number"
						:rules="rules.price"
						flat
						dense
						solo
						hide-details
					></v-text-field>
					<!-- <TextField
						v-if="data.type === 'fix_price'"
						:value.sync="data.price"
						placeholder="Введите цену"
						type="number"
						:rules="rules.price"
					></TextField> -->
					<!-- Плавающая цена -->
					<div v-else-if="data.type === 'floating_price'" class="d-flex">
						<v-text-field
							v-model="data.one_price"
							style="max-width: 150px;"
							class="border-all mr-2"
							placeholder="от"
							type="number"
							:rules="rules.price"
							flat
							dense
							solo
							hide-details
						></v-text-field>

						<v-text-field
							v-model="data.second_price"
							style="max-width: 150px;"
							class="border-all"
							placeholder="до"
							type="number"
							:rules="rules.price"
							flat
							dense
							solo
							hide-details
						></v-text-field>

						<!-- <TextField
							:value.sync="data.one_price"
							placeholder="от"
							type="number"
							:rules="rules.price"
						></TextField>
						<TextField
							:value.sync="data.second_price"
							placeholder="до"
							type="number"
							:rules="rules.price"
						></TextField> -->
					</div>
				</div>
			</v-form>
		</v-col>

		<v-col class="pt-0 d-flex align-center">
			<Button small :loading="loading" @click.native="handleClick">
				Сохранить
			</Button>
			<legend v-if="alert" class="subtitle-2 font-weight-light red--text ml-3">
				{{ alert }}
			</legend>
		</v-col>
	</v-row>
</template>

<script>
import { priceTypes } from "@/helpers/variables";

export default {
	name: "PricelistItemEdit",

	props: {
		item: {
			type: Object,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			priceTypes,
			alert: null,
			data: null,
			rules: {
				required: [v => !!v || ""],
				price: [v => (!!v && v >= 0) || ""],
			},
		};
	},

	methods: {
		handleClick() {
			if (!this.$refs.form.validate()) {
				this.alert = "Заполните все выделенные поля";
				return;
			}

			this.alert = null;

			this.$emit("save", this.data);
		},
	},

	created() {
		this.data = { ...this.item, category_id: this.item.category._id };
		if (!this.data.type) this.data.type = "fix_price";
		else if (this.data.type.name)
			this.data.type = this.priceTypes.find(e =>
				e.text.includes(this.data.type.name)
			).value;
	},
};
</script>

<style></style>
