<template>
	<v-col cols="1">
		<div v-if="!isEdit" class="d-flex">
			<v-btn color="primary" icon @click="$emit('edit')">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
			<v-btn color="red darken-2" icon @click="$emit('delete')">
				<v-icon>mdi-delete</v-icon>
			</v-btn>
		</div>
		<div v-else class="d-flex justify-center">
			<v-btn icon @click="$emit('edit')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</div>
	</v-col>
</template>

<script>
export default {
	name: "PricelistItemBtns",

	props: {
		isEdit: {
			type: Boolean,
			required: true,
		},
	},
};
</script>

<style></style>
