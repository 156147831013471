<template>
	<li class="pricelist__item grey lighten-3 mb-7 pa-4 rounded-lg">
		<v-progress-linear
			v-if="loading === item._id"
			indeterminate
			color="primary"
		></v-progress-linear>

		<header class="mb-2">
			<div class="d-flex flex-column">
				<v-row>
					<!-- Категория -->
					<v-col cols="2">
						<v-autocomplete
							v-model="data.category_id"
							class="border-all"
							placeholder="Категория"
							solo
							hide-details
							dense
							flat
							:items="catalog.request_categories"
							item-text="name"
							item-value="id"
							:disabled="!isEdit"
						>
						</v-autocomplete>
					</v-col>

					<v-spacer></v-spacer>

					<PricelistItemBtns
						:is-edit="isEdit"
						@edit="isEdit = !isEdit"
						@delete="showConfirmModal = true"
					/>
				</v-row>
			</div>
		</header>

		<div v-if="!isEdit" class="d-flex justify-space-between align-center">
			<p class="subtitle-1 black--text ma-0">
				{{ `${index + 1}. ${data.name}` }}
			</p>
			<h6 class="text-h6">{{ price }}</h6>
		</div>

		<PricelistItemEdit
			v-else
			:item="data"
			:loading="loading"
			@save="handleChange"
		/>

		<ModalConfirm
			v-if="showConfirmModal"
			title="Удаление расценки"
			@close-modal="handleDelete"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>
						Вы действительно хотите удалить данную услугу
					</span>
					<strong>{{ item.name }}?</strong>
				</div>
			</template>
		</ModalConfirm>
	</li>
</template>

<script>
import { priceTypes } from "@/helpers/variables";
import ModalConfirm from "@/components/app/ModalConfirm";
import PricelistItemBtns from "@/components/PricelistItemBtns";
import PricelistItemEdit from "@/components/PricelistItemEdit";

import { mapGetters } from "vuex";
export default {
	name: "PricelistItem",

	components: { ModalConfirm, PricelistItemBtns, PricelistItemEdit },

	props: {
		item: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
	},

	data() {
		return {
			priceTypes,
			loading: false,
			data: { ...this.item, category_id: this.item.category._id },
			isEdit: false,
			showConfirmModal: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
		price() {
			const { type } = this.data;
			if (type?.name.includes("Договорная")) return "Договорная";
			if (type?.name.includes("Плавающая")) {
				return `${this.data.one_price} - ${this.data.second_price} ₸`;
			}
			const { price } = this.data;
			return +price ? `${price} ₸` : "Бесплатно";
		},
	},

	methods: {
		async handleChange(data) {
			data = { ...data, category_id: this.data.category_id };
			try {
				this.loading = true;
				await this.$api.ksk.edit_price({ id: this.item._id, data });
				const name = this.priceTypes.find(e => e.value === data.type).text;
				this.data = { ...this.data, ...data, type: { name } };
				this.isEdit = false;
				this.$emit("save", this.data);
			} catch (error) {
				this.$root.snackbar.show(error);
			} finally {
				this.loading = false;
			}
		},

		async handleDelete(isConfirmed) {
			if (!isConfirmed) return;

			await this.$api.ksk.delete_price(this.item._id);

			this.$emit("update");
		},
	},
};
</script>

<style></style>
