<template>
	<v-card v-bind="$attrs" outlined>
		<v-card-text>
			<v-form ref="form" class="pricelist__form" lazy-validation>
				<div class="d-flex justify-space-between align-center mb-2">
					<v-select
						v-model="data.category_id"
						style="max-width: 200px;"
						class="border-all flex-grow-0"
						placeholder="Категория"
						flat
						dense
						solo
						hide-details
						:items="catalog.request_categories"
						item-value="id"
						item-text="name"
						:rules="rules.required"
					></v-select>

					<v-btn icon @click="$emit('close')">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<!-- Название услуги -->
				<v-text-field
					v-model="data.name"
					class="border-all flex-grow-1"
					placeholder="Введите название услуги"
					flat
					dense
					solo
					hide-details
					:rules="rules.required"
				></v-text-field>

				<div class="pricelist__inputs d-flex mt-3">
					<!-- Тип цены -->
					<v-select
						v-model="data.type"
						style="max-width: 250px;"
						class="border-all mr-4"
						placeholder="Выберите тип цены"
						flat
						dense
						solo
						hide-details
						:items="priceTypes"
						:rules="rules.required"
					/>
					<!-- Фиксированная цена -->
					<v-text-field
						v-if="data.type === 'fix_price'"
						style="max-width: 150px;"
						v-model="data.price"
						class="border-all"
						placeholder="Введите цену"
						type="number"
						:rules="rules.price"
						flat
						dense
						solo
						hide-details
					></v-text-field>
					<!-- <TextField
						v-if="data.type === 'fix_price'"
						:value.sync="data.price"
						placeholder="Введите цену"
						type="number"
						:rules="rules.price"
					></TextField> -->
					<!-- Плавающая цена -->
					<div v-else-if="data.type === 'floating_price'" class="d-flex">
						<v-text-field
							v-model="data.one_price"
							style="max-width: 150px;"
							class="border-all mr-2"
							placeholder="от"
							type="number"
							:rules="rules.price"
							flat
							dense
							solo
							hide-details
						></v-text-field>

						<v-text-field
							v-model="data.second_price"
							style="max-width: 150px;"
							class="border-all"
							placeholder="до"
							type="number"
							:rules="rules.price"
							flat
							dense
							solo
							hide-details
						></v-text-field>

						<!-- <TextField
							:value.sync="data.one_price"
							placeholder="от"
							type="number"
							:rules="rules.price"
						></TextField>
						<TextField
							:value.sync="data.second_price"
							placeholder="до"
							type="number"
							:rules="rules.price"
						></TextField> -->
					</div>
				</div>
				<div class="d-flex align-center mt-3">
					<v-btn
						:loading="loading"
						class="subtitle-2 text-capitalize mr-3"
						small
						depressed
						color="primary"
						@click="handleSubmit"
					>
						Добавить
					</v-btn>

					<span v-if="alert" class="subtitle-2 font-weight-light red--text">
						{{ alert }}
					</span>
				</div>
			</v-form>
		</v-card-text>
	</v-card>
</template>

<script>
import { priceTypes } from "@/helpers/variables";
import { mapGetters } from "vuex";

export default {
	name: "PricelistForm",

	data() {
		return {
			priceTypes,
			alert: null,
			data: {
				name: "",
				price: null,
				type: null,
				category_id: null,
				one_price: null,
				second_price: null,
			},
			rules: {
				required: [v => !!v || ""],
				price: [v => (!!v && v >= 0) || ""],
			},
			loading: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
	},

	methods: {
		async handleSubmit() {
			if (!this.$refs.form.validate()) {
				this.alert = "Заполните все выделенные поля";
				return;
			}

			this.alert = null;

			try {
				this.loading = true;
				await this.$api.ksk.add_price(this.data);
				this.$emit("success");
				this.$emit("close");
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.v-text-field {
	border-color: #2eb85c;
}
.v-card {
	border: thin solid #2eb85c !important;
	background: rgba(46, 184, 92, 0.09);
}
</style>
