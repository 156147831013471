<template>
	<v-container class="pricelist">
		<v-row>
			<v-card class="pa-4 elevation-0" width="100%" height="100%">
				<v-card-title
					class="d-flex justify-space-between align-center subtitle-1"
				>
					<div class="d-flex flex-column">
						<h3>
							Прайскурант по платным услугам
						</h3>
						<h5>
							Добавляйте услуги и расценки для быстрого обслуживания
						</h5>
					</div>

					<div class="d-flex flex-column justify-center align-center">
						<v-img :src="qr" width="70px"></v-img>
						<Button x-small text @click.native="downloadQr">Скачать QR</Button>
					</div>
				</v-card-title>

				<v-card-text>
					<!-- Фильтры -->
					<v-row>
						<v-col cols="12" sm="8">
							<!-- Поиск по ключевым -->
							<v-text-field
								v-model="params.name"
								class="border-all"
								placeholder="Поиск по ключевым словам"
								flat
								dense
								solo
								hide-details
								clearable
								prepend-inner-icon="mdi-magnify"
								@input="searchByKeyword"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="4">
							<!-- Категория -->
							<v-autocomplete
								v-model="params.category_id"
								class="border-all"
								placeholder="Категория"
								prepend-inner-icon="mdi-filter"
								hide-details
								dense
								solo
								flat
								clearable
								:items="catalog.request_categories"
								item-text="name"
								item-value="id"
								@change="loadData"
							>
							</v-autocomplete>
						</v-col>
					</v-row>

					<!-- Кнопки -->
					<div class="d-flex justify-space-between mt-3 mb-6">
						<Button @click.native="showCreateModal = true">
							Добавить услугу
						</Button>
					</div>

					<PricelistForm
						v-if="showCreateModal"
						class="mb-7"
						@success="loadData"
						@close="showCreateModal = false"
					/>

					<!-- Loading -->
					<v-row v-if="loading === true">
						<v-col cols="12" v-for="(q, i) in 5" :key="i">
							<v-skeleton-loader
								height="120px"
								type="image"
							></v-skeleton-loader>
						</v-col>
					</v-row>

					<!-- Questions -->
					<ul v-else-if="pricelist.length" class="pa-0">
						<PricelistItem
							v-for="(item, index) of pricelist"
							:key="item._id"
							:item="item"
							:index="index"
							@update="loadData"
							@save="handleSave"
						/>
					</ul>

					<h3 v-else>По вашему запросу ничего не найдено</h3>

					<v-pagination
						v-if="!loading && pricelist.length"
						v-model="options.page"
						:length="options.pages"
						:total-visible="7"
						@input="changePage"
					></v-pagination>
				</v-card-text>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import PricelistItem from "@/components/PricelistItem";
import PricelistForm from "@/components/PricelistForm";
import ModalConfirm from "@/components/app/ModalConfirm";

export default {
	name: "Pricelist",

	components: {
		PricelistItem,
		PricelistForm,
		ModalConfirm,
	},

	watch: {
		"params.name"(val) {
			if (val === "") {
				this.params.name = null;
			}
		},
	},

	data() {
		return {
			params: {
				category_id: null,
				name: null,
			},
			options: {
				pages: 1,
				page: +this.$route.params.page || 1,
			},
			qr: null,
			loading: false,
			pricelist: [],
			searchTimer: null,
			showCreateModal: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
	},

	methods: {
		async downloadQr() {
			return axios({
				url: this.qr,
				method: "get",
				responseType: "arraybuffer",
			})
				.then(res => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "QR Прайслист.png");
					document.body.appendChild(link);
					link.click();
				})
				.catch(err => {
					throw err;
				});
		},

		async loadData() {
			try {
				this.loading = true;
				const res = await this.$api.ksk.load_pricelist({
					...this.params,
					page: this.options.page,
				});
				this.pricelist = res.records;
				this.options.pages = res.pages;
				this.options.page = res.page;
				console.log(this.pricelist);
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},

		async loadQr() {
			try {
				this.qr = await this.$api.ksk.pricelist_qr();
			} catch (error) {
				console.error(error);
			}
		},

		changePage() {
			this.$router.replace({ query: { page: this.options.page } });
			this.loadData();
		},

		async searchByKeyword() {
			if (this.params.name === "") this.params.name = null;
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(() => this.loadData(), 1000);
		},

		handleSave(item) {
			const idx = this.pricelist.findIndex(e => e._id === item._id);
			this.pricelist.splice(idx, 1, item);
		},
	},

	mounted() {
		this.loadData();
		this.loadQr();
	},
};
</script>

<style lang="scss" scoped>
.pricelist {
	height: 90vh;
}
</style>
